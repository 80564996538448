	/*
  	Flaticon icon font: Flaticon
  	Creation date: 25/04/2016 10:42
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-gymnast-girl:before { content: "\f11a"; }
.flaticon-weightlifting-2:before { content: "\f11b"; }
.flaticon-exercise:before { content: "\f102"; }
.flaticon-exercise-1:before { content: "\f103"; }
.flaticon-exercise-2:before { content: "\f104"; }
.flaticon-exercise-3:before { content: "\f105"; }
.flaticon-gym:before { content: "\f106"; }
.flaticon-gym-1:before { content: "\f107"; }
.flaticon-gym-2:before { content: "\f108"; }
.flaticon-gym-3:before { content: "\f109"; }
.flaticon-line:before { content: "\f10a"; }
.flaticon-man:before { content: "\f10b"; }
.flaticon-people:before { content: "\f10c"; }
.flaticon-people-1:before { content: "\f10d"; }
.flaticon-people-2:before { content: "\f10e"; }
.flaticon-people-3:before { content: "\f10f"; }
.flaticon-people-4:before { content: "\f110"; }
.flaticon-people-5:before { content: "\f111"; }
.flaticon-people-6:before { content: "\f112"; }
.flaticon-person:before { content: "\f113"; }
.flaticon-signs:before { content: "\f114"; }
.flaticon-silhouette:before { content: "\f115"; }
.flaticon-silhouette-1:before { content: "\f116"; }
.flaticon-silhouette-2:before { content: "\f117"; }
.flaticon-silhouette-3:before { content: "\f118"; }
.flaticon-smile:before { content: "\f119"; }
.flaticon-strength:before { content: "\f11a"; }
.flaticon-strength-1:before { content: "\f11b"; }
.flaticon-tool:before { content: "\f11c"; }
